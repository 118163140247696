import {AnyAction} from 'redux'
import {
  CLOSE_MONTHLY_CALENDAR_EVENT,
  CLOSE_MONTHLY_CALENDAR_POPUP,
  INIT_CALENDAR,
  OPEN_MONTHLY_CALENDAR_EVENT,
  OPEN_MONTHLY_CALENDAR_POPUP,
} from '../actions/calendar-layout'
import {LOAD_CALENDAR_EVENTS} from '../actions/events'
import {CalendarLayout} from '../types/state'

const defaultState: CalendarLayout = {
  referenceDate: null,
  monthly: {
    selectedDate: null,
    selectedEventId: null,
  },
  weeks: [],
  fullMonthText: '',
  weekDayNames: [],
  loadedPages: [],
  loading: null,
}

export const calendarLayout = (state = defaultState, action: AnyAction): CalendarLayout => {
  switch (action.type) {
    case INIT_CALENDAR:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: null,
          selectedEventId: null,
        },
        weekDayNames: action.payload.weekDayNames,
        weeks: action.payload.weeks,
        fullMonthText: action.payload.fullMonthText,
        referenceDate: action.payload.referenceDate,
        loading: null,
      }
    case LOAD_CALENDAR_EVENTS.REQUEST:
      return {
        ...state,
        loading: action.args[1],
      }
    case LOAD_CALENDAR_EVENTS.SUCCESS:
      const referenceDate = action.payload.dates.common.referenceDate
      return {
        ...state,
        referenceDate,
        loadedPages: [...state.loadedPages, referenceDate],
      }
    case LOAD_CALENDAR_EVENTS.FAILURE:
      return {
        ...state,
        loading: null,
      }
    case OPEN_MONTHLY_CALENDAR_POPUP:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: action.payload.date,
          selectedEventId: null,
        },
      }
    case CLOSE_MONTHLY_CALENDAR_POPUP:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: null,
        },
      }
    case OPEN_MONTHLY_CALENDAR_EVENT:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: action.payload.date,
          selectedEventId: action.payload.eventId,
        },
      }
    case CLOSE_MONTHLY_CALENDAR_EVENT:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedEventId: null,
        },
      }
    default:
      return state
  }
}
